import { getRequestConfig } from "next-intl/server";
import config from "./config";

export const DEFAULT_LOCALE = "en";
//export const LOCALES = ["en", "no", "fr"] as const;
export const LOCALES = ["en"] as const;
export type Locale = (typeof LOCALES)[number];
export const localePrefix = "as-needed";

export default getRequestConfig(async ({ locale }) => {
  return {
    messages: (await import(`../messages/${locale}.json`)).default,
    timeZone: config.timeZone
  };
});
