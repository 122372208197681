import { useState } from "react";

export interface IEvent {
  target: {
    value: string;
  };
}

type inputType = "text" | "email" | "password";

const validEmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateField = (type: string, value: string, required: boolean) => {
  const isValid = () => {
    if (!required) return true;
    switch (type) {
      case "text":
        return value?.length > 0;
      case "email":
        return value?.length > 0 && validEmailRegex.test(value);
      case "password":
        return value?.length > 5;
      default:
        return value?.length > 0;
    }
  };

  const Message = () => {
    switch (type) {
      case "text":
        return "Please enter a value";
      case "email":
        return "Please enter a valid email";
      case "password":
        return "Please enter a valid password";
      default:
        return "Please enter a value";
    }
  };

  return {
    isValid: isValid(),
    message: Message()
  };
};

const useInput = (
  type: inputType,
  override?: { isValid?: boolean; message?: string; required?: boolean }
) => {
  const [value, setValue] = useState("" as string);
  const [required, setRequired] = useState(override?.required ?? false);

  const onChange = (event: IEvent) => {
    setValue(event.target.value);
  };

  const isValid =
    override?.isValid || validateField(type, value, required).isValid;
  const message =
    override?.message || validateField(type, value, required).message;

  /**
   *
   * @deprecated use required with boolean instead
   */
  const deprecated__require = () => {
    // TODO: Warning Cannot update a component (`Checkout`) while rendering a different component (`CheckoutForm`)
    // should not be used any longer
    setRequired(true);
    return true;
  };

  return {
    setValue,
    isValid,
    message,
    type,
    value,
    onChange,
    clear: () => setValue(""),
    required,
    deprecated__require
  };
};
export default useInput;
