import type { OnChangeValue } from "react-select";

import Dropdown from "../Dropdown";

export type DropdownOption = {
  value: string;
  label: string;
};

interface UIProps {
  options: DropdownOption[];
  onChange: (newValue: OnChangeValue<DropdownOption, false>) => void;
  value: string;
}

const CountryDropdownUI = ({ options, onChange, value }: UIProps) => {
  return (
    <Dropdown
      options={options}
      onChange={onChange}
      value={value}
      markets={true}
    />
  );
};

export default CountryDropdownUI;
